<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card flat>
      <v-card-title
        class="ticketing-card-title justify-center ma-0"
        color="black"
      >
        <div class="d-flex flex-grow-1 justify-space-between mt-5 mr-5">
          <v-spacer />
          <p>Request detail - Ticket ID: {{ ticketing.request_id }}</p>
          <v-spacer />
          <v-icon
            v-if="ticketing.viewTicketingDetailPopup"
            class="text-right mr-n8 mt-n16"
            color="red"
            size="28"
            @click="closeDetails"
          >
            mdi-close
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <div class="d-flex">
            <v-col
              cols="6"
              class="pl-0"
            >
              <v-text-field
                v-model="request.request_count"
                label="Request N°"
                :disabled="true"
                type="text"
              />
            </v-col>
            <v-col
              cols="6"
              class="pr-0"
            >
              <v-text-field
                v-model="request.request_type"
                label="Request mode"
                :disabled="true"
                type="text"
              />
            </v-col>
          </div>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="requestDate"
                label="Request date"
                :disabled="true"
                type="text"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="evasionDate"
                label="Evasion date"
                :disabled="true"
                type="text"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="evasionDateLimit"
                label="Evasion expected"
                :disabled="true"
                type="text"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="request.request_state"
                :items="requestState"
                label="Request state"
                type="text"
              />
            </v-col>
            <v-col cols="6">
              <v-textarea
                v-model="request.notes"
                rows="1"
                auto-grow
                label="Note"
                type="text"
                :disabled="requestIsDeleted"
                :counter="selectedMeteoType.is_auto ? 100 : 512"
              />
            </v-col>
          </v-row>
          <v-checkbox
            v-if="request.request_state === 'EMITTED'"
            v-model="requestShallow.send_email"
            class="mt-0"
            label="Send emitted request email"
            color="#0da344"
            @change="storeTempData(requestShallow.send_email, 'sendMail')"
          />
          <v-row>
            <v-col cols="6">
              <!-- <v-select
                v-model="selectedDocumentType"
                :items="ticketing.requestTypes.documentTypes"
                :disabled="requestIsDeleted"
                item-text="document_type"
                label="Document types"
                return-object
                @input="storeTempData(selectedDocumentType, 'docType')"
                @change="updateMeteoTypeList()"
              /> -->
              <v-textarea
                v-model="selectedDocumentTypeName"
                rows="1"
                :disabled="true"
                auto-grow
                label="Document type"
                type="text"
              />
            </v-col>
            <v-col cols="6">
              <!-- <v-select
                v-model="selectedMeteoType"
                :items="ticketing.requestTypes.meteoTypes"
                :disabled="requestIsDeleted"
                item-text="meteo_type"
                label="Meteo types"
                return-object
                @input="storeTempData(selectedMeteoType, 'meteoType')"
              /> -->
              <v-textarea
                v-model="selectedMeteoTypeName"
                rows="1"
                :disabled="true"
                auto-grow
                label="Meteo type"
                type="text"
              />
            </v-col>
          </v-row>
          <v-menu
            ref="rangeMenu"
            v-model="rangeMenu"
            :close-on-content-click="false"
            :return-value.sync="rangeDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
            @input="storeTempData(rangeDate, 'rangeDate')"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="rangeDate"
                :disabled="requestIsDeleted"
                multiple
                chips
                small-chips
                label="Range date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="rangeDate"
              no-title
              range
              scrollable
              :min="rangeDate[0]"
              :max="getCalendarMaxDate()"
            >
              <v-btn
                text
                color="primary"
                @click="closeDateRange()"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="rangeDate.length < 2"
                @click="$refs.rangeMenu.save(rangeDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-radio-group
            v-model="selectedAddressType"
            :disabled="requestIsDeleted"
            row
            mandatory
            @change="resetAddress(); storeTempData(selectedAddressType, 'addressType')"
          >
            <v-radio
              label="Address"
              :ripple="true"
              value="Address"
              color="#0da344"
            />
            <v-radio
              v-if="selectedDocumentType.document_type !== 'Report meteorologico comunale'"
              label="Manual coordinates"
              :ripple="true"
              value="Manual"
              color="#0da344"
              @click="isManualCoordinates = true"
            />
          </v-radio-group>
          <div v-if="selectedAddressType === 'Address'">
            <input
              ref="autocomplete"
              placeholder="Search on Google Maps"
              :disabled="requestIsDeleted"
              class="search-location mb-2"
              onfocus="value = ''"
              type="text"
              @click="autocompleteInit()"
            >
          </div>
          <v-row v-else>
            <v-col
              cols="6"
            >
              <v-text-field
                id="lat"
                v-model="latitude"
                label="Latitude"
                :rules="number_rules"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                id="lng"
                v-model="longitude"
                label="Longitude"
                :rules="number_rules"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-model="map.address"
            label="Address"
            :disabled="true"
          />
          <v-btn
            id="submit"
            color="#0da344"
            small
            style="color:white"
            :disabled="selectedAddressType === 'Address'"
            @click="dispatchManualGeocoderMarker()"
          >
            Find Address
          </v-btn>
          <br>
          <v-btn
            color="#0da344"
            small
            style="color:white"
            class="mt-2 mb-2"
            :disabled="checkForm()"
            @click="addLocation()"
          >
            Add location
          </v-btn>
          <v-data-table
            :headers="ticketing.ticketingRequestHeaders"
            :items="requestShallow.locations"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <delete
                :id="item.name"
                :disabled="requestIsDeleted"
                @deleted="deleteItem(item)"
              />
            </template>
            <template
              v-slot:[`item.start_date`]="{ item }"
            >
              <span>
                {{ item.start_date && item.start_date.indexOf('-') > 2 ? moment(item.start_date).format('DD-MM-YYYY') : item.start_date }}
              </span>
            </template>
            <template
              v-slot:[`item.end_date`]="{ item }"
            >
              <span>
                {{ item.end_date && item.end_date.indexOf('-') > 2 ? moment(item.end_date).format('DD-MM-YYYY') : item.end_date }}
              </span>
            </template>
            <template
              v-slot:[`item.latitude`]="{ item }"
            >
              <map-coordinate
                :coordinate="item.latitude"
                :type="item.meteo_type"
              />
            </template>
            <template
              v-slot:[`item.longitude`]="{ item }"
            >
              <map-coordinate
                :coordinate="item.longitude"
                :type="item.meteo_type"
              />
            </template>
            <template v-slot:[`item.meteo_type`]="{ item }">
              <split-document-or-meteo-type :type="item.meteo_type" />
            </template>
          </v-data-table>
          <v-data-table
            :headers="ticketing.linkedAppUserCredentialsHeaders"
            :items="requestArray"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <delete
                :id="item.name"
                @deleted="deleteItem(item)"
              />
            </template>
            <template v-slot:[`item.meteo_type`]="{ item }">
              <split-document-or-meteo-type :type="item.meteo_type" />
            </template>
          </v-data-table>
        </v-form>
        <document-s3-table
          v-if="request.app_user_ticket_id != ''"
          ref="documentS3TableRef"
          :request="request"
          :app-user-ticket-id="request.app_user_ticket_id"
          :ticketing-path="true"
          :is-tabs="isTabs"
          :request-is-deleted="requestIsDeleted"
          :show-extra-informations="showExtraInformations"
          :request-type="request.request_type === 'AM' ? 'M' : request.request_type"
          @new-document="newDocument($event)"
        />
        <!-- <v-card-text
          v-if="showExtraInformations"
          class="d-flex justify-space-between mt-5"
        >
          <v-divider />
          <p class="mx-8 mt-n4 text-h6">
            Richiedi approfondimento manuale
          </p>
          <v-divider />
        </v-card-text> -->
        <div
          v-if="showExtraInformations || request.additional_notes"
          class="mt-5"
        >
          <v-card-title
            class="pa-0"
            style="font-size: 18px;"
          >
            <b>Additional notes</b>
          </v-card-title>
          <v-textarea
            v-model="additionalNotes"
            auto-grow
            class="py-0"
            placeholder="Esempio: controllare la giornata gg/mm/aaaa del periodo richiesto perché vi è un contenzioso o perizia di appello o c’è evidenza di un danno legato all’avversità richiesta"
            counter="512"
            maxlength="512"
            minlength="20"
            :error-messages="getErrorMessage"
          />
        </div>
        <ticketing-priority-selection
          v-if="showExtraInformations || request.additional_notes || request.request_type === 'M'"
          class="mt-5"
          :evasion-limit="request.evasion_limit"
          :location-items="request.locations"
          :is-provinciale="isProvinciale"
          :request-number="request.request_number"
          @update-evasion-limit="storeTempData($event, 'evasionLimit')"
        />
      </v-card-text>
      <div class="d-flex justify-center">
        <v-card-actions>
          <v-btn
            v-if="requestShallow.request_type === 'A' && requestShallow.request_state === 'EMITTED' && checkIsAutoIsManual()"
            :color="showExtraInformations ? 'red' : '#0da344'"
            style="color:white"
            @click="showExtraInformationsToggle"
          >
            {{ showExtraInformations ? 'Cancel' : 'Request manual examination' }}
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            color="#0da344"
            style="color:white"
            :disabled="!valid || checkModifyButtonIsDisabled"
            @click="modifyRequest()"
          >
            {{ showExtraInformations ? 'Send manual examination' : 'Modify request' }}
          </v-btn>
          <v-btn
            v-if="ticketing.viewTicketingDetailTabs"
            text
            @click="closeTab()"
          >
            Close request
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getRegExp } from '@/formRules.js';
import { requestStates } from '@/constants.js';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-business-days';

export default {
  components: {
    DocumentS3Table: () => import('@/components/utils/DocumentS3Table'),
    delete: () => import('@/components/utils/Delete'),
    SplitDocumentOrMeteoType: () => import('@/components/utils/SplitDocumentOrMeteoType'),
    TicketingPrioritySelection: () => import('@/components/services/TicketingPrioritySelection'),
    MapCoordinate: () => import('@/components/utils/MapCoordinate')
  },
  props: {
    request: {
      type: Object,
      default: null
    },
    isTabs: {
      type: Boolean,
      default: false
    },
    filterRangeDate: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      document_id: '',
      disableForm: true,
      valid: false,
      rangeMenu: false,
      overlay: false,
      newDocumentAdded: false,
      isManualCoordinates: false,
      latitude: '',
      longitude: '',
      location_id: '',
      selectedAddressType: 'Address',
      selectedRequestState: '',
      // locationItems: [],
      rangeDate: [],
      selectedDocumentType: {},
      selectedMeteoType: {},
      selectedDocumentTypeName: '',
      selectedMeteoTypeName: '',
      requestShallow: {},
      requestArray: [],
      number_rules: [...getRegExp('numbers_dot_only')],
      showExtraInformations: false,
      additionalNotes: '',
      requestIsDeleted: false
    };
  },
  computed: {
    ...mapState(['ticketing', 'policy', 'map']),
    requestState () {
      return requestStates;
    },
    requestDate() {
      return this.request.request_date && this.request.request_date.indexOf('-') > 2 ? moment(this.request.request_date).format('DD-MM-YYYY') : this.request.request_date;
    },
    evasionDate() {
      return this.request.evasion_date && this.request.evasion_date.indexOf('-') > 2 ? moment(this.request.evasion_date).format('DD-MM-YYYY') : this.request.evasion_date;
    },
    evasionDateLimit() {
      return this.request.evasion_limit && this.request.evasion_limit.indexOf('-') > 2 ? moment(this.request.evasion_limit).format('DD-MM-YYYY') : this.request.evasion_limit;
    },
    isRelazione() {
      return Object.keys(this.selectedDocumentType).length > 0 && this.selectedDocumentType.document_type.toLowerCase().includes('relazione');
    },
    isProvinciale() {
      return Object.keys(this.selectedDocumentType).length > 0 && this.selectedDocumentType.document_type.toLowerCase().includes('provinciale');
    },
    getErrorMessage() {
      let errorMessage = '';

      if (this.showExtraInformations && this.additionalNotes.length < 20) {
        errorMessage = 'Additional notes must contain at least 20 characters.';
      }

      return errorMessage;
    },
    checkModifyButtonIsDisabled() {
      if (this.showExtraInformations) {
        const noteIsValid = this.additionalNotes.length >= 20;
        const evasionDateIsValid = this.requestShallow.evasion_limit.length > 0;

        return !noteIsValid || !evasionDateIsValid;
      } else {
        return this.request.request_state === 'EMITTED' && this.ticketing.documentS3ItemsRadarmeteo.length <= 0;
      }
    }
  },
  watch: {
    $route() {}
  },
  async mounted() {
    this.ticketing.request_id = this.request.request_id;
    await this.getTicketingConfiguration();

    this.requestShallow = JSON.parse(JSON.stringify(this.request));
    this.requestArray.push(this.request);
    this.setTempData();

    this.selectedRequestState = this.request.request_state;
    this.selectedDocumentTypeName = this.request.document_type.split(',')[0].trim();
    this.selectedMeteoTypeName = this.request.meteo_type[0];
    this.additionalNotes = this.requestShallow.additional_notes || '';
    this.requestIsDeleted = this.request.request_state === 'DELETED';

    const selectedDocument = this.ticketing.ticketingServiceConfiguration?.config?.find(item => item.document_type === this.selectedDocumentTypeName);

    if (selectedDocument) {
      this.selectedDocumentType = selectedDocument;
      const selectedMeteo = this.selectedDocumentType?.meteo_types?.find(item => item.name === this.selectedMeteoTypeName);

      if (selectedMeteo) {
        this.selectedMeteoType = selectedMeteo;
      }
    }
  },
  methods: {
    ...mapActions(['storeGetTicketingRequestComplete', 'storeGetTicketingServiceConfiguration', 'ticketingReverseGeocoding', 'editTicketingRequestAction', 'linkLocationToTicketingRequestAction', 'deleteLinkedLocationToTicketingRequestAction', 'storeEnabledServicesPolicy', 'reorganizeSlots', 'storeListFilteredTicketingRequest']),
    storeTempData(item, name) {
      if (this.isTabs) {
        if (name === 'sendMail') {
          this.ticketing.tempSendMail[`req${this.request.request_number}`] = item;
        }

        if (name === 'rangeDate') {
          this.ticketing.tempRangeDate[`req${this.request.request_number}`] = item;
        }

        if (name === 'meteoType') {
          this.ticketing.tempMeteoType[`req${this.request.request_number}`] = item;
        }

        if (name === 'docType') {
          this.ticketing.tempDocType[`req${this.request.request_number}`] = item;
        }

        if (name === 'addressType') {
          this.ticketing.tempAddressType[`req${this.request.request_number}`] = item;
        }
      }

      if (name === 'evasionLimit') {
        if (this.isTabs) {
          this.ticketing.tempEvasionLimit[`req${this.request.request_number}`] = item;
        } else {
          this.updateEvasionLimit(item);
        }
      }
    },
    setTempData() {
      if (this.isTabs) {
        if (this.ticketing.tempRangeDate[`req${this.request.request_number}`] && this.ticketing.tempRangeDate[`req${this.request.request_number}`] !== '') {
          this.rangeDate = this.ticketing.tempRangeDate[`req${this.request.request_number}`];
        }

        if (this.ticketing.tempMeteoType[`req${this.request.request_number}`] && this.ticketing.tempMeteoType[`req${this.request.request_number}`] !== '') {
          this.selectedMeteoType = this.ticketing.tempMeteoType[`req${this.request.request_number}`];
        }

        if (this.ticketing.tempDocType[`req${this.request.request_number}`] && this.ticketing.tempDocType[`req${this.request.request_number}`] !== '') {
          this.selectedDocumentType = this.ticketing.tempDocType[`req${this.request.request_number}`];
        }

        if (this.ticketing.tempAddressType[`req${this.request.request_number}`] && this.ticketing.tempAddressType[`req${this.request.request_number}`] !== '') {
          this.selectedAddressType = this.ticketing.tempAddressType[`req${this.request.request_number}`];
        }

        if (this.ticketing.tempSendMail[`req${this.request.request_number}`] && this.ticketing.tempSendMail[`req${this.request.request_number}`] !== '') {
          this.requestShallow.send_email = this.ticketing.tempSendMail[`req${this.request.request_number}`];
        }

        if (this.ticketing.tempLocations[`req${this.request.request_number}`] && this.ticketing.tempLocations[`req${this.request.request_number}`].length > 0) {
          this.ticketing.tempLocations[`req${this.request.request_number}`].forEach(item => this.requestShallow.locations.push(item));
        }

        if (this.ticketing.tempLocationsToRemove[`req${this.request.request_number}`] && this.ticketing.tempLocationsToRemove[`req${this.request.request_number}`].length > 0) {
          this.ticketing.tempLocationsToRemove[`req${this.request.request_number}`].forEach(item =>
            this.requestShallow.locations.splice(this.requestShallow.locations.findIndex(element => element.location_id == item.location_id), 1));
        }
      }
    },
    checkForm() {
      let requiredParams = this.valid && this.rangeDate.length;
      requiredParams = requiredParams && this.latitude !== '' && this.longitude !== '';
      // requiredParams = requiredParams && Object.keys(this.selectedDocumentType).length !== 0 && Object.keys(this.selectedMeteoType).length !== 0;
      requiredParams = requiredParams && !this.isManualCoordinates && this.map.address !== '';

      return !requiredParams;
    },
    checkIsAutoIsManual() {
      if (this.selectedMeteoType.is_auto && this.selectedMeteoType.is_manual) {
        return true;
      } else {
        return false;
      }
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.$refs.rangeMenu.save([]);
    },
    autocompleteInit() {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { types: ['geocode'] }
      );

      this.autocomplete.addListener('place_changed', () => {
        let place = '';

        this.autocomplete.getPlace().address_components.forEach(item => {
          if (item.types[0] === 'administrative_area_level_3') {
            place += item.short_name;
          }

          if (place !== '' && item.types[0] === 'administrative_area_level_2') {
            place += ` (${item.short_name})`;
          }
        });

        if (place === '') {
          place = this.autocomplete.getPlace().formatted_address;
        }

        this.map.address = place;
        this.latitude = this.autocomplete.getPlace().geometry.location.lat();
        this.longitude = this.autocomplete.getPlace().geometry.location.lng();
      });
    },
    resetAddress() {
      this.map.address = '';
      this.latitude = '';
      this.longitude = '';
      this.isManualCoordinates = false;
    },
    dispatchManualGeocoderMarker() {
      this.ticketingReverseGeocoding([this.longitude, this.latitude]);
      this.isManualCoordinates = false;
    },
    // updateMeteoTypeList() {
    //   this.ticketing.ticketingServiceConfiguration.config.forEach(element => {
    //     if (this.selectedDocumentType.document_type === element.document_type) {
    //       this.ticketing.requestTypes.meteoTypes = [];
    //       element.meteo_types?.forEach(item => {
    //         this.ticketing.requestTypes.meteoTypes.push(item.name);
    //       });
    //     }
    //   });

    //   // Reset rangeDate
    //   this.rangeDate = [];
    // },
    async getTicketingConfiguration() {
      this.overlay = true;
      await this.storeGetTicketingServiceConfiguration(this.request.service_id);
      this.overlay = false;

      this.ticket_id = this.ticketing.ticketingServiceConfiguration.ticket_id;

      // if (this.ticketing.ticketingServiceConfiguration.config?.length > 0) {
      //   this.ticketing.requestTypes = {
      //     documentTypes: [],
      //     meteoTypes: []
      //   };
      //   this.ticketing.ticketingServiceConfiguration.config.forEach(item => {
      //     if (item.document_type != '') {
      //       this.ticketing.requestTypes.documentTypes.push({
      //         document_type: item.document_type
      //         ticket_id: item.ticket_id,
      //         service_id: item.service_id
      //       });

      //       if (!this.isTabs || this.ticketing.tempDocType[`req${this.request.request_number}`] === '') {
      //         const [document_type, document_type_key] = this.request.document_type.split(', ');
      //         const ticket_id = document_type_key.split('.');
      //         this.selectedDocumentType = {
      //           document_type: document_type,
      //           ticket_id: ticket_id[1],
      //           service_id: this.request.service_id
      //         };
      //       }
      //     }

      //     if (item.meteo_type != '') {
      //       this.ticketing.requestTypes.meteoTypes.push({
      //         meteo_type: item.meteo_type,
      //         ticket_id: item.ticket_id,
      //         service_id: item.service_id
      //       });
      //     }
      //   });
      //   this.ticketing.requestTypes.documentTypes.sort((a, b) => (a.document_type.toUpperCase() > b.document_type.toUpperCase() ? 1 : -1));
      //   this.ticketing.requestTypes.meteoTypes.sort((a, b) => (a.meteo_type.toUpperCase() > b.meteo_type.toUpperCase() ? 1 : -1));
      // }
    },
    async getTicketingRequestList(newValue) {
      const start_date = this.filterRangeDate[0];
      const end_date = this.filterRangeDate[1];

      const data = {
        service_id: newValue,
        filter_start_date: start_date,
        filter_end_date: end_date
      };

      this.overlay = true;
      await this.storeGetTicketingRequestComplete(data);
      this.overlay = false;
    },
    addLocation() {
      const startDate = this.rangeDate[0];
      const endDate = this.rangeDate[1];
      this.location_id = uuidv4();

      this.requestShallow.locations.push({
        app_user_ticket_id: this.request.app_user_ticket_id,
        request_id: this.request.request_id,
        location_id: this.location_id,
        latitude: this.latitude,
        longitude: this.longitude,
        meteo_type: `${this.selectedMeteoTypeName}, ServiceCard.${this.ticket_id}.${this.selectedMeteoType.meteo_id}.meteoType`,
        address: this.map.address,
        start_date: startDate,
        end_date: endDate
      });

      if (this.isTabs) {
        this.ticketing.tempLocations[`req${this.request.request_number}`].push({
          app_user_ticket_id: this.request.app_user_ticket_id,
          request_id: this.request.request_id,
          location_id: this.location_id,
          latitude: this.latitude,
          longitude: this.longitude,
          meteo_type: `${this.selectedMeteoTypeName}, ServiceCard.${this.ticket_id}.${this.selectedMeteoType.meteo_id}.meteoType`,
          address: this.map.address,
          start_date: startDate,
          end_date: endDate
        });
      }
    },
    deleteItem(item) {
      this.requestShallow.locations.splice(this.requestShallow.locations.indexOf(item), 1);

      if (this.isTabs) {
        if (this.ticketing.tempLocations[`req${this.request.request_number}`].indexOf(item) !== -1) {
          this.ticketing.tempLocations[`req${this.request.request_number}`].splice(this.ticketing.tempLocations[`req${this.request.request_number}`].indexOf(item), 1);
        } else {
          this.ticketing.tempLocationsToRemove[`req${this.request.request_number}`].push(item);
        }
      }
    },
    getCalendarMaxDate() {
      const todayDate = moment();
      const limitDate = this.isRelazione ? todayDate : moment(todayDate).subtract(2, 'days');

      return moment(limitDate).format('YYYY-MM-DD');
    },
    checkRequestType() {
      if (this.request.request_type === 'A') {
        if (this.showExtraInformations) {
          return 'AM';
        } else {
          return 'A';
        }
      } else {
        return this.request.request_type;
      }
    },
    checkAdditionalInformation() {
      if (this.request.request_type === 'AM' || this.showExtraInformations) {
        return this.additionalNotes || '';
      }

      return '';
    },
    checkEvasionLimit () {
      if (this.request.request_type === 'A') {
        if (this.showExtraInformations) {
          return this.requestShallow.evasion_limit;
        } else {
          return this.request.evasion_limit;
        }
      } else {
        return this.requestShallow.evasion_limit;
      }
    },
    checkRequestState () {
      if (this.request.request_type === 'A' && this.showExtraInformations && this.request.request_state === 'EMITTED') {
        return 'SENT';
      } else {
        return this.request.request_state;
      }
    },
    async modifyRequest() {
      this.requestShallow.document_type = `${this.selectedDocumentType.document_type}, ServiceCard.${this.selectedDocumentType.ticket_id}.documentType`;

      if (this.request.request_state == 'EMITTED') {
        this.request.evasion_date = this.request.evasion_date.indexOf('-') === this.requestShallow.evasion_date.indexOf('-')
          ? this.request.evasion_date
          : moment(this.request.evasion_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        this.request.evasion_date = this.request.evasion_date || moment().format('YYYY-MM-DD');
      } else {
        this.request.evasion_date = '';
      }

      this.request.request_date = this.request.request_date.indexOf('-') === this.requestShallow.request_date.indexOf('-')
        ? this.request.request_date
        : moment(this.request.request_date, 'DD-MM-YYYY').format('YYYY-MM-DD');

      if (this.ticketing.tempEvasionLimit[`req${this.request.request_number}`] && this.ticketing.tempEvasionLimit[`req${this.request.request_number}`] !== '') {
        this.request.evasion_limit = this.ticketing.tempEvasionLimit[`req${this.request.request_number}`];
      }

      this.overlay = true;
      await this.$refs.documentS3TableRef.addDocument();

      const sendEmailForNewDocument = this.request.request_state === 'EMITTED' && this.newDocumentAdded && this.requestShallow.send_email;

      const match = Object.entries(this.requestShallow)
        .sort()
        .toString() ===
            Object.entries(this.request)
              .sort()
              .toString();

      if (!match || sendEmailForNewDocument || this.showExtraInformations) {
        const data = {
          app_user_id: this.request.app_user_id,
          service_type: 'TICKET'
        };
        await this.storeEnabledServicesPolicy(data);
        const group_notification = this.policy.enabledServicesPolicy.TICKET.permissions.some(item => item.permission_name === 'Invio notifica di gruppo');

        const requestData = {
          app_user_ticket_id: this.request.app_user_ticket_id,
          request_id: this.request.request_id,
          request_type: this.checkRequestType(),
          document_type: `${this.selectedDocumentTypeName}, ServiceCard.${this.ticket_id}.${this.selectedDocumentType.document_id}.documentType`,
          username: this.request.username,
          evasion_date: this.request.evasion_date.indexOf('-') > 2 || this.request.evasion_date.indexOf('-') == -1
            ? this.request.evasion_date
            : moment(this.request.evasion_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          request_date: this.request.request_date.indexOf('-') > 2
            ? this.request.request_date
            : moment(this.request.request_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          evasion_limit: this.checkEvasionLimit(),
          request_state: this.checkRequestState(),
          editing_user: 'radarmeteo',
          send_email: this.requestShallow.send_email,
          group_notification: group_notification,
          notes: this.request.notes.replace(/\\n/g, '\n').replace(/\\t/g, '\t') || '',
          additional_notes: this.checkAdditionalInformation(),
          email: this.request.email,
          additional_info: this.showExtraInformations
        };

        await this.addReorganizeSlotsParams(requestData);
        await this.editTicketingRequestAction(requestData);
      }

      const addedOnly = this.requestShallow.locations.filter(storeItem => {
        return !this.request.locations.some(item => {
          return storeItem.location_id == item.location_id;
        });
      });

      const deletedOnly = this.request.locations.filter(storeItem => {
        return !this.requestShallow.locations.some(item => {
          return storeItem.location_id == item.location_id;
        });
      });

      if (this.ticketing.createRequestState) {
        if (addedOnly.length > 0) {
          await this.linkLocationToTicketingRequestAction(addedOnly);
        }

        if (deletedOnly.length > 0) {
          await this.deleteLinkedLocationToTicketingRequestAction(deletedOnly);
        }

        if (this.$route.path === '/ticketing-list') {
          // await this.getTicketingRequestList();
          this.overlay = true;
          await this.storeListFilteredTicketingRequest(this.ticketing.searchFilters);
          this.overlay = false;
        } else {
          await this.getTicketingRequestList(this.request.service_id);
        }
      }

      this.overlay = false;

      if (this.ticketing.viewTicketingDetailTabs) {
        this.$emit('remove-tab', this.request);
      }

      this.ticketing.viewTicketingDetailPopup = false;
    },
    async addReorganizeSlotsParams(requestData) {
      const isStandardRequest = !requestData.document_type.includes('provinciale') && !requestData.document_type.includes('Relazione');
      const isEmitted = requestData.request_state === 'EMITTED' && requestData.request_state !== this.requestShallow.request_state;
      const isDeleted = requestData.request_state === 'DELETED' && requestData.request_state !== this.requestShallow.request_state;
      const isMoved = requestData.request_state === 'SENT' && requestData.request_state === this.requestShallow.request_state && requestData.evasion_limit !== this.requestShallow.evasion_limit;

      if (isStandardRequest && isEmitted) {
        const dateToCheck = moment(requestData.evasion_limit, 'YYYY-MM-DD').prevBusinessDay().format('YYYY-MM-DD');
        requestData.require_reorganize_slots = moment(requestData.evasion_date, 'YYYY-MM-DD').isSameOrBefore(dateToCheck, 'day');

        if (requestData.require_reorganize_slots) {
          requestData.slots_to_reorganize = await this.reorganizeSlots(requestData.evasion_limit);
        }
      }

      if (isStandardRequest && isDeleted) {
        const dateToCheck = moment().nextBusinessDay().format('YYYY-MM-DD');
        requestData.require_reorganize_slots = moment(requestData.evasion_limit, 'YYYY-MM-DD').isSameOrAfter(dateToCheck, 'day');

        if (requestData.require_reorganize_slots) {
          requestData.slots_to_reorganize = await this.reorganizeSlots(requestData.evasion_limit);
        }
      }

      if (isStandardRequest && isMoved) {
        requestData.require_reorganize_slots = requestData.evasion_limit !== this.requestShallow.evasion_limit;

        if (requestData.require_reorganize_slots) {
          requestData.slots_to_reorganize = await this.reorganizeSlots(this.requestShallow.evasion_limit);
        }
      }
    },
    closeTab() {
      this.$emit('remove-tab', this.request);
    },
    closeDetails() {
      this.ticketing.viewTicketingDetailPopup = false;
      this.request.request_state = this.requestShallow.request_state;
      this.request.notes = this.requestShallow.notes;
    },
    updateEvasionLimit(value) {
      this.requestShallow.evasion_limit = value;
    },
    newDocument(added) {
      this.newDocumentAdded = added;
    },
    showExtraInformationsToggle() {
      this.showExtraInformations = !this.showExtraInformations;
    }
  }
};
</script>

<style scoped>
.ticketing-card-title {
  color: #0da344;
  background-color: #f4f3f3;
  margin-top: 1%;
  font-size: 22px;
}
input[type="text"] {
    font-size:16px;
}
</style>
